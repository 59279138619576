import Box from 'components/commons/Box'
import ScrollDrag from 'components/commons/ScrollDrag'

interface IMobileContentSliderProps {
  renderTopComponent?: JSX.Element | any
  renderBottomComponent?: JSX.Element | any
  numOfRow?: number
  children?: JSX.Element | any
}

const MobileContentSlider = ({
  renderTopComponent,
  renderBottomComponent,
  numOfRow = 2,
  children,
}: IMobileContentSliderProps) => {
  return (
    <ScrollDrag
      sx={{
        display: {
          md: 'none',
        },
      }}
    >
      {numOfRow > 1 ? (
        <Box
          className="container-category"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginRight: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '12px',
            }}
          >
            {renderTopComponent}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '12px',
            }}
          >
            {renderBottomComponent}
          </Box>
        </Box>
      ) : (
        <>{children}</>
      )}
    </ScrollDrag>
  )
}

export default MobileContentSlider
