import React, { ContextType } from 'react'
import { styled } from '@mui/material/styles'
import {
  ScrollMenu,
  VisibilityContext,
} from 'react-horizontal-scrolling-menu'
import useDrag from 'components/commons/ScrollDrag/useDrag'
import { SxProps } from '@mui/material'

const Wrapper = styled('div')({
  '.react-horizontal-scrolling-menu--scroll-container': {
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

interface IScrollDragProps {
  children: any
  separatorClassName?: string
  scrollContainerClassName?: string
  sx?: SxProps
}

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>
function onWheel(
  apiObj: scrollVisibilityApiType,
  ev: React.WheelEvent,
): void {
  const isThouchpad =
    Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15
  if (isThouchpad) {
    ev.stopPropagation()
    return
  }
  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}

export default function ScrollDrag({
  sx,
  ...props
}: IScrollDragProps) {
  const { dragStart, dragStop, dragMove } = useDrag()
  const handleDrag: any =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  return (
    <Wrapper sx={sx} onMouseLeave={dragStop}>
      <ScrollMenu
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        separatorClassName={props.separatorClassName}
        scrollContainerClassName={props.scrollContainerClassName}
      >
        {props.children}
      </ScrollMenu>
    </Wrapper>
  )
}
