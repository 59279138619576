import { SxProps, styled } from '@mui/material/styles'

import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import isEmpty from 'helpers/is-empty'

type ContainerProps = {
  draggable?: boolean
}
interface ISectionContainer extends ContainerProps {
  children: React.ReactNode
  sectionName?: string
  showViewAll?: boolean
  onClickViewAll?: Function
  subtitle?: string
  sxContainerTitle?: SxProps
  sxContainer?: SxProps
}

export const StyledSectionContainer = styled(Box)<ContainerProps>(
  ({ draggable = true, theme }) => ({
    width: draggable ? '100vw' : '100%',
    marginTop: '0px !important',
    paddingLeft: draggable ? '16px' : '0px',
    paddingRight: draggable ? '16px' : '0px',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: draggable ? '32px 0px' : '0px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: draggable ? '32px 0px ' : '0px',
    },
    '@media only screen and (min-width: 1440px)': {
      padding: draggable ? '32px 0px' : '0px',
    },
  }),
)

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumBold,
  fontFamily: 'Source Sans 3',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h4Desk,
    fontFamily: 'Open Sans',
  },
  color: theme.palette.text.primary,
  left: '0',
  letterSpacing: '0.02em',
  alignSelf: 'center',
  flex: 1,
  zIndex: 2,
}))

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalRegular,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.mediumRegular,
  },
  color: theme.palette.text.secondary,
  letterSpacing: '0.0015em',
}))

function SectionContainer({
  children,
  subtitle,
  onClickViewAll,
  sectionName: title,
  showViewAll = false,
  draggable = true,
  sxContainerTitle,
  sxContainer,
}: ISectionContainer) {
  const handleClickSeeMore = () => {
    if (onClickViewAll) onClickViewAll()
  }

  return (
    <StyledSectionContainer sx={sxContainer} draggable={draggable}>
      <Stack
        spacing={{ xs: '12px', md: 2 }}
        sx={{ position: 'relative' }}
      >
        {title && title !== '' && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1.5}
            sx={{ paddingRight: { xs: '16px', md: '0px' } }}
          >
            <StyledTypography sx={sxContainerTitle}>
              {title}
            </StyledTypography>
            {showViewAll && (
              <Button
                buttonVariant="nude"
                onClick={handleClickSeeMore}
                sx={{
                  padding: `0px !important`,
                  margin: `0px !important`,
                  justifyContent: 'end',
                }}
              >
                <ArrowForwardIcon />
              </Button>
            )}
          </Stack>
        )}
        {!isEmpty(subtitle) && (
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        )}
        {children}
      </Stack>
    </StyledSectionContainer>
  )
}

export default SectionContainer
