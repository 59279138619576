import Carousel from 'react-material-ui-carousel'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeftRounded'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRightRounded'
import styles from './slider.module.scss'

interface IDesktopContentSliderProps {
  children?: any
}
const DesktopContentSlider = ({
  children,
}: IDesktopContentSliderProps) => {
  return (
    <Carousel
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        overflow: {
          xs: 'hidden',
          md: 'visible',
        },
      }}
      className={styles.container}
      autoPlay={false}
      swipe={false}
      NextIcon={
        <ArrowCircleRightIcon
          sx={({ palette }) => ({
            display: {
              xs: 'none',
              md: 'block',
            },
            position: 'absolute',
            transform: {
              xs: 'translate(-10px, 50%)',
              md: 'translate(40%, 50%)',
            },
            fontSize: 45,
            color: palette.text.tertiery,
          })}
        />
      }
      PrevIcon={
        <ArrowCircleLeftIcon
          sx={({ palette }) => ({
            display: {
              xs: 'none',
              md: 'block',
            },
            position: 'absolute',
            transform: {
              xs: 'translate(10px, 50%)',
              md: 'translate(-40%, 50%)',
            },
            fontSize: 45,
            color: palette.text.tertiery,
          })}
        />
      }
      navButtonsProps={{
        style: {
          backgroundColor: 'transparent',
        },
        className: styles.navButtons,
      }}
      indicators={false}
      navButtonsAlwaysVisible
      cycleNavigation={false}
      fullHeightHover={false}
    >
      {children}
    </Carousel>
  )
}

export default DesktopContentSlider
