import { SxProps } from '@mui/material'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import redirect from 'helpers/redirect'
import { useTranslation } from 'react-i18next'

interface IProps {
  name?: string
  sxProps?: SxProps
  url?: string
  handleRedirect?: Function
}

function SeeAllCard({
  name = '',
  sxProps,
  url,
  handleRedirect,
}: IProps) {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{
        background: 'pink',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        textAlign: 'center',
        cursor: 'pointer',
        ...sxProps,
      }}
      onClick={() => {
        if (url && url !== '') {
          redirect(url)
        } else {
          handleRedirect()
        }
      }}
    >
      <Typography
        sx={{
          color: 'white',
          fontWeight: '700',
          margin: 'auto 10%',
        }}
      >
        {t('viewAll')} {name}
      </Typography>
    </Stack>
  )
}

export default SeeAllCard
