import { Skeleton, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledStack = styled(Stack)({
  width: '100%',
  height: '306px',
  overflow: 'hidden',
})

const StyledImage = styled(Skeleton)({
  height: '100%',
  width: '100%',
  img: {
    borderRadius: '12px',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '163px',
  },
})

const StyledCard = styled(Skeleton)(({ theme }) => ({
  minWidth: '150px',
  position: 'relative',
  paddingLeft: 0,
  cursor: 'pointer',
  borderRadius: 12,
  background: theme.palette.text.primary,
}))

export const ContentSliderSkeleton = () => {
  return (
    <StyledStack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1.5}
    >
      {Array.from(Array(6)).map((_, i) => (
        <StyledCard
          key={i}
          height="100%"
          width="100%"
          variant="rectangular"
          animation="pulse"
        >
          <StyledImage />
          <Skeleton height="100%" variant="rectangular" />
        </StyledCard>
      ))}
    </StyledStack>
  )
}
