/**
 * This file is used to place business logic related to digital content section.
 * API requests, show/hide sections should be placed here.
 */
import apiClient from 'helpers/api-client'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Chip,
  GridSize,
  styled,
  SxProps,
  useTheme,
} from '@mui/material'

import trackEvent from 'src/trackers'

import redirect from 'helpers/redirect'
import { getUrlWithParams } from 'helpers/url'
import imageResizer from 'helpers/image-resizer'
import { sendLog } from 'helpers/log'
import isEmpty from 'helpers/is-empty'
import { isGuestMode } from 'helpers/cookie'
import { getIdToken } from 'helpers/auth'

import { getSectionDetail } from 'services/campaign'
import { getEventList } from 'services/event'

import { ContentSliderSkeleton } from './Skeleton'
import SectionContainer from '../../SectionContainer/v2'
import Grid from 'components/commons/Grid'
import MobileContentSlider from 'components/domains/Home/MobileContentSlider'
import DesktopContentSlider from 'components/domains/Home/DesktopContentSlider'
import { FilterType } from 'stores/domains/Event/EventTypes/interface'
import Stack from 'components/commons/Stack'
import dayjs from 'dayjs'
import SeeAllCard from 'components/commons/Card/SeeAllCard'
import GeneralProductCard from 'components/commons/Card/GeneralProductCard'

const FilterBtn = styled(Chip)({
  height: '32px',
  padding: '8px 16px',
})

type TypeEventStatusFilter = 'upcoming' | 'event_ended'

interface IProps {
  categoryId?: number | string
  title: string
  minSales?: number
  sortBy?: string
  sortDirection?: 'DESC' | 'ASC'
  passSortToExploreParam?: boolean
  isThematicCampaign?: boolean
  sectionId?: string
  sectionType?: string
  endpointUrl?: string
  token?: string
  limit?: number
  params?: object
  creatorCategoryId?: number | string
  creatorCategory?: string
  desktopGridSize?: GridSize
  creatorId?: string
  excludeId?: string
  withoutHeaders?: boolean
  hideShowallButton?: boolean
  isEvent?: boolean
  eventStatusFilter?: TypeEventStatusFilter[]
  sxContainerTitle?: SxProps
  sxContainer?: SxProps
  filters?: FilterType[]
  isOtherSection?: boolean
  t?: any
}

const ContentSlider = ({
  categoryId = null,
  title = 'Konten Paling Hits',
  minSales = 0,
  sortBy = 'created_date',
  sortDirection = 'DESC',
  passSortToExploreParam = false,
  isThematicCampaign = false,
  sectionId = '',
  sectionType,
  endpointUrl = '/content/guest/v1/product/search',
  token,
  limit = 20,
  params = {},
  creatorCategoryId = '',
  creatorCategory = '',
  desktopGridSize = 2,
  creatorId = null,
  excludeId = null,
  withoutHeaders = false,
  hideShowallButton = false,
  isEvent = false,
  eventStatusFilter = ['upcoming'],
  sxContainerTitle,
  filters,
  sxContainer,
  isOtherSection = false,
}: IProps) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [contents, setContents] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const { pathname, query } = useRouter()

  const trackerData = { pathname, query }
  const { source_action } = query
  const sourceTitle = {
    'Konten Rekomendasi': 'content_recommendation',
    'Konten Digital Terbaru': 'content_latest_upload',
    'Konten Lainnya': 'similar_content',
    'Event Lainnya': 'similar_event',
  }
  const isRecommendation = title === 'Konten Rekomendasi'

  const getStateSubName = () => {
    if (sourceTitle[title]) return sourceTitle[title]
    if (isThematicCampaign) return 'thematic_section'
    if (isRecommendation) return 'content_recommendation'
    return 'content_latest_upload'
  }

  const getSourceAction = () => {
    if (sourceTitle[title])
      return `&source_action=${sourceTitle[title]}`
    if (!isEmpty(source_action))
      return `&source_action=${source_action}`
    if (isThematicCampaign) return '&source_action=thematic_section'
    if (isRecommendation)
      return '&source_action=content_recommendation'
    return '&source_action=content_latest_upload'
  }

  const sourceAction = getSourceAction()
  const source = `?source=${pathname}${sourceAction}`

  const getContents = async () => {
    try {
      setIsLoading(true)
      if (isThematicCampaign) {
        const url = `/campaign${
          isGuestMode() ? '/guest' : ''
        }/v1/section/${sectionId}?`
        const response = await getSectionDetail(url, {
          page: 1,
          limit: 20,
        })
        const contentList = response?.data?.data?.content_list || []

        setLoadMore(contentList.length >= 5)
        setContents(contentList)
      } else {
        if (isEvent && !isOtherSection) {
          const response = await getEventList({
            page: 1,
            limit: limit,
            filter: eventStatusFilter,
          })

          return setContents(response.data.data.event || [])
        }

        let url = `${endpointUrl}?page=1&limit=${limit}`

        // url for latest digital content section for home page
        if (sourceTitle[title] === 'content_latest_upload')
          url = `${endpointUrl}?pc=1&lc=${limit}&tab=content&exclude_cl=true`

        // To only include the `category_id` if necessary
        if (categoryId !== null)
          url = `${url}&category_id=${categoryId}`

        if (creatorId !== null) url = `${url}&creator_id=${creatorId}`

        if (excludeId !== null) url = `${url}&exclude_id=${excludeId}`

        if (!isOtherSection)
          url = `${url}&sort_by=${sortBy}&sort=${sortDirection}`

        if (minSales >= 1) url = `${url}&min_total_sales=${minSales}`

        if (isEvent) url = `${url}&type=event`

        if (eventStatusFilter && isEvent)
          url = `${url}&filter_status=${eventStatusFilter}`

        const recommendation = !isGuestMode()
          ? '&sources=recommendation'
          : ''

        url = `${url}${recommendation}`

        const response = await apiClient({
          url,
          token,
          params,
          method: 'GET',
          withoutHeaders: withoutHeaders,
          cache: url.includes('similar')
            ? false
            : {
                interpretHeader: true,
                cacheTakeover: false,
              },
        })
        if (sourceTitle[title] === 'content_latest_upload') {
          setContents(response?.data?.data?.content || [])
        } else {
          setContents(response.data.data || [])
        }
      }
    } catch (error) {
      sendLog(`ERROR(${error?.code}): ${error?.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleShowMoreThematicContent = () => {
    trackEvent.home('click_see_section_detail', trackerData, {
      section_id: sectionId,
      section_title: title,
      section_content_type: sectionType,
    })
    redirect(
      `/section/${sectionId}?source=${pathname}&source_action=click_see_section_detail`,
    )
  }

  const getUrlSeeAll = () => {
    if (!sectionId) {
      let params = {
        status: 'content',
        ref: '/',
      }

      if (passSortToExploreParam) {
        params['sort_by'] = sortBy
        params['sort_direction'] = sortDirection
      }

      if (categoryId !== null)
        params = { ...params, ...{ category_id: categoryId } }

      trackEvent.home('click_browse_search', trackerData, {
        click_button: `browse_${title
          .toLowerCase()
          .replace(/ /g, '_')}_content`,
        state_sub_name: getStateSubName(),
      })
      const url = getUrlWithParams(
        `/browse?source=${pathname}&source_action=${
          isRecommendation
            ? 'content_recommendation'
            : 'content_latest_upload'
        }`,
        params,
      )
      return url
    }

    return `/section/${sectionId}?source=${pathname}&source_action=click_see_all`
  }

  const handeClickShowMore = () => {
    redirect(getUrlSeeAll())
  }

  const getDefaultTrackerParams = (item, index, isEvent = false) => {
    let eventTrackerParams = {}
    if (isEvent) {
      const endTime = dayjs(item?.event_summary?.sales_ended_at)
      const saleTimeLeft = endTime.diff(new Date().toString(), 'hour')
      const isMultipleTicketOptions = item?.ticket_options?.length > 1
      eventTrackerParams = {
        event_is_multiple_ticket_option: isMultipleTicketOptions,
        event_status: item?.event_summary?.status,
        event_max_quota: item?.event_summary?.quota,
        event_price_initial: item?.price,
        event_price_final: item?.sale_price,
        event_sale_end_date: item?.event_summary?.sales_ended_at,
        event_sale_countdown_hrs: saleTimeLeft,
        event_start_date: item?.event_summary?.event_started_at,
        event_end_date: item?.event_summary?.event_ended_at,
      }
    }

    return {
      user_type: getIdToken() ? 'supporter' : 'guest',
      creator_category_id: isEmpty(String(creatorCategoryId))
        ? item?.creator?.category?.id
        : creatorCategoryId,
      creator_category: isEmpty(String(creatorCategory))
        ? item?.creator?.category?.name
        : creatorCategory,
      creator_user_id: item?.creator?.creator_user_id || '',
      creator_name: item?.creator?.name || '',
      creator_id: item?.creator?.creator_id || '',
      creator_group: item?.creator?.creator_group || '',
      is_community_leader: item?.creator?.is_community_leader,
      content_title: item?.title || '',
      content_id: item?.product_id || '',
      sort_number: index || 1,
      content_state:
        item?.status_order?.toLowerCase() === 'completed'
          ? 'purchased'
          : 'unpurchased',
      content_photo_image_path: item?.cover || '',
      content_items_sold: item?.stats?.total_sales || 0,
      content_price_initial: item?.price || 0,
      content_price_final: item?.sale_price || 0,
      content_type: item?.type?.name || '',
      selected_browse_and_search_menu: isThematicCampaign
        ? undefined
        : 'content_tab',
      content_category_id: item?.category?.id || '',
      content_category: item?.category?.name || '',
      state_sub_name: getStateSubName(),
      section_id: !isEmpty(sectionId) ? sectionId : undefined,
      section_title: !isEmpty(title) ? title : undefined,
      section_content_type: !isEmpty(sectionType)
        ? sectionType
        : undefined,

      subscribe_to_creator: item?.is_subscriber,
      content_access_source: item?.content_access_source || 'none',
      package_id: item?.subscribe_package_list?.map(
        (package_item) => package_item.package_id,
      ),
      package_title: item?.subscribe_package_list?.map(
        (package_item) => package_item.title,
      ),
      ...eventTrackerParams,
    }
  }

  const handleClickSelectedContent = (item, index) => {
    const trackerParams = getDefaultTrackerParams(
      item,
      index,
      isEvent,
    )

    trackEvent.home(
      isEvent ? 'select_event' : 'select_content',
      trackerData,
      trackerParams,
    )
    const targetUrl = `/${isEvent ? 'event' : 'content'}/${
      item.product_id
    }${source}`
    // TODO Check if the redirection work as expected
    redirect(targetUrl)
  }

  useEffect(() => {
    getContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDigitalItem = (item, index) => {
    const trackerParams = getDefaultTrackerParams(item, index)
    const isRecommendationEvent = isEvent && item?.event_summary

    const startDate = isRecommendationEvent
      ? item?.event_summary?.event_started_at
      : item?.metadata?.event_started_at
    const endDate = isRecommendationEvent
      ? item?.event_summary?.event_ended_at
      : item?.metadata?.event_ended_at
    const location = isRecommendationEvent
      ? item?.event_summary?.location_city
      : item?.metadata?.location_city
    const eventStatus = isRecommendationEvent
      ? item?.event_summary?.status
      : item?.metadata?.event_status?.name
    const eventTimezone = isRecommendationEvent
      ? item?.event_summary?.event_timezone
      : item?.metadata?.event_timezone

    return (
      <GeneralProductCard
        key={`product-${item.product_id}`}
        onClick={() => handleClickSelectedContent(item, index)}
        href={`/${isEvent ? 'event' : 'content'}/${item.product_id}`}
        image={imageResizer(item.cover, '300w')}
        typeName={item.type.name}
        title={item.title}
        creatorName={item.creator.name}
        salePrice={item.sale_price}
        price={item.price}
        teaser={item.teaser_file}
        playable={!!item.teaser_file || false}
        trackDefaultStates={trackerParams}
        isVerified={item.creator.verified}
        locked={item?.is_lock}
        productType={isEvent ? 'event' : 'content'}
        startDate={startDate}
        endDate={endDate}
        location={location}
        eventStatus={eventStatus}
        eventTimezone={eventTimezone}
      />
    )
  }

  const renderItem = () => {
    const isShowSeeAllCard = contents.length > 6 && !hideShowallButton
    let newDataArray = isShowSeeAllCard ? [...contents, {}] : contents

    return newDataArray?.map((item, index) => {
      if (isShowSeeAllCard && index === newDataArray.length - 1) {
        return (
          <SeeAllCard
            key={index}
            name={title}
            url={getUrlSeeAll()}
            sxProps={{
              width: '168px',
              height: 'auto',
            }}
          />
        )
      } else {
        return renderDigitalItem(item, index)
      }
    })
  }

  const renderItemCarousel = () => {
    let contentItem = []
    let contentContainer = []
    const isShowSeeAllCard = contents.length > 6 && !hideShowallButton
    let newDataArray = isShowSeeAllCard ? [...contents, {}] : contents

    for (let index = 0; index < newDataArray.length; index++) {
      const category = newDataArray[index]
      const element = (
        <Grid
          item
          md={desktopGridSize as any}
          lg={desktopGridSize as any}
          key={category.id}
        >
          {isShowSeeAllCard && index === newDataArray.length - 1 ? (
            <SeeAllCard name={title} url={getUrlSeeAll()} />
          ) : (
            renderDigitalItem(category, index)
          )}
        </Grid>
      )

      contentItem.push(element)
      if (
        contentItem.length === 6 ||
        index === newDataArray.length - 1 ||
        contentItem.length === 12 / parseInt(String(desktopGridSize))
      ) {
        const container = (
          <Grid
            key={`container-${index}`}
            container
            rowGap={{ lg: 2 }}
            columnSpacing={{ lg: 2 }}
          >
            {contentItem.map((item) => item)}
          </Grid>
        )
        contentContainer.push(container)
        contentItem = []
      }
    }

    return contentContainer.map((item) => item)
  }

  // To only display if it is loading, or if it has at least 1 content
  if (isLoading || (!isLoading && contents.length >= 1))
    return (
      <SectionContainer
        showViewAll={
          hideShowallButton
            ? false
            : isThematicCampaign
            ? loadMore
            : title.toLowerCase() !== 'konten lainnya'
        }
        onClickViewAll={
          isThematicCampaign
            ? handleShowMoreThematicContent
            : handeClickShowMore
        }
        sectionName={title}
        sxContainerTitle={sxContainerTitle}
        sxContainer={sxContainer}
      >
        {isLoading ? (
          <ContentSliderSkeleton />
        ) : (
          <>
            {filters && (
              <Stack
                direction={'row'}
                sx={{
                  gap: '8px',
                  width: '100%',
                  overflowX: 'scroll',
                  scrollbarWidth: 'none',
                }}
              >
                {filters?.length > 0 &&
                  filters.map((type, _i) => (
                    <FilterBtn
                      id={`b-home-event-type${type.name}`}
                      key={`home-event-type${type.name}`}
                      label={type.name}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.tiptip[500]}`,
                        color: theme.palette.secondary.main,
                        ':hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                  ))}
              </Stack>
            )}
            <DesktopContentSlider>
              {renderItemCarousel()}
            </DesktopContentSlider>
            <MobileContentSlider numOfRow={1}>
              <Stack
                direction={'row'}
                sx={{ padding: '4px', gap: '8px' }}
              >
                {renderItem()}
              </Stack>
            </MobileContentSlider>
          </>
        )}
      </SectionContainer>
    )

  return null
}

export default ContentSlider
